import './App.css';
import "./css/style.css"
import "./css/responsive.css"
import React, { lazy, Suspense } from 'react'
import { Helmet } from "react-helmet"
import logo from "../src/images/logonew.png"
const AppRouter = lazy(() => import('./routes'))
function App() {
  return (
    <>
      <Suspense fallback={<div className={'text-center mt-3 loadingLazy' }>
      <img src={logo} alt="" />
        Loading Please Wait....</div>}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>No Sweat Desk Yoga</title>
          <link rel="canonical" href="https://mynosweatyoga.com/static/media/logo.6e8e168b945fa76ee0e4.png" />
          <meta property="og:image" content="https://mynosweatyoga.com/static/media/logo.6e8e168b945fa76ee0e4.png" />
        </Helmet>
        <AppRouter />
      </Suspense>

    </>
  );
}

export default App;
